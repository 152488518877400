import React from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import RightSidebar from "../components/RightSidebar.js";

const AboutPage = () => {
  return (
    <>
      <Head
        title="About Us"
        description="Who is the authors of Austin City Tacos, let's find out?"
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>About Us</h1>
              <p>
                Howdy there & thanks for visiting our little taco aficionado
                website, we are "Mr Handsome #1" and "Mr Handsome #2".
              </p>
              <p>
                Austin City Tacos is a collection of 2 independent food
                reviewers. We choose to remain anonymous since our only goal
                with this website is to provide our humble opinions for tacos in
                Austin and all the fun things in Austin. We are not affiliated
                with any restaurants or groups which would cloud our minds with
                the pure love of our eternal quest: "To Find the Best Taco(s) in
                Austin".
              </p>
              <img src="/icons/mr-handsome-1.png" /> &nbsp;{" "}
              <img src="/icons/mr-handsome-2.png" />
              <p>
                We hope would love to hear suggestions and feedback on our
                reviews, or especially any suggestions for upcoming
                restaurants... so email us at{" "}
                <a href="mailto:austincitytacos@gmail.com">
                  austincitytacos@gmail.com
                </a>
                .
              </p>
            </div>
            <div className="right-sidebar">
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default AboutPage;
